import testImage from '@/public/images/review/image.png';
import type { IReview } from '@/src/components/home.interface';

export const reviews: IReview[] = [
  {
    name: 'Rebecca Howard',
    occupation: 'Special Education Teacher',
    imageUrl: '/images/reviewer-1.svg',
    type: ['prominent'],
    text: 'I generally don’t write reviews but am compelled to do so, given how easy data collection has become since I started with AbleSpace. All my paras enter data from their devices, and AbleSpace automatically collates it and prints out beautiful graphs and reports. IEP meetings have become much more fun as a result!',
  },
  {
    name: 'Nicole Ward',
    occupation: 'Speech Language Pathologist',
    imageUrl: '/images/reviewer-2.svg',
    type: ['prominent'],
    text: 'AbleSpace has become my go-to app for data collection. All my data - progress monitoring, assessments, and billing is kept in one place. I can also collect data from my phone during a session, and AbleSpace crunches the numbers for me and generates reports automatically. I am not sure how I was able to ‘SLP’ before this!',
  },
  {
    name: 'Kelly Knox',
    occupation: 'Special Education Teacher',
    imageUrl: '',
    type: ['carousel'],
    text: '15 years and 6 data systems later, finally I have found the perfect solution for data collection. It is so easy yet so powerful. And love the selection of available graphs.',
  },
  {
    name: 'Ashley Lowe',
    occupation: 'Speech Language Pathologist',
    imageUrl: '',
    type: ['carousel'],
    text: 'Loved the colors and the ease of progress monitoring. The team has put real effort into building this app, and I see myself using it for years to come.',
  },
  {
    name: 'Kathleen Reed',
    occupation: 'Special Education Teacher',
    imageUrl: '',
    type: ['carousel'],
    text: 'Thank you for being so responsive to my support requests. This will be my most used app for the entire school year. I was also impressed that it has built-in assessments too.',
  },
  {
    name: 'Debra Hansen',
    occupation: 'Special Education Teacher',
    imageUrl: '',
    type: ['carousel'],
    text: 'I was surprised to find how simple AbleSpace was to use. I never looked at a tutorial and could figure out most things by myself. There is real value I place on this ease of use.',
  },
  {
    name: 'Emily Solis',
    occupation: 'Occupational Therapist',
    imageUrl: '',
    type: ['carousel'],
    text: 'I love the fact that this is an all-in-one package. Data collection, assessments, and therapy materials. Also, everything seems so easy to use.',
  },
  {
    name: 'Veronica Ponce',
    occupation: 'Physical Therapist',
    imageUrl: '',
    text: 'I just love how easy it is to collect data with AbleSpace. I have tried multiple data collection systems but always found myself returning to pen and paper since they were difficult to use. Not with AbleSpace, though. It will surely be the most used app for me this year.',
  },
  {
    name: 'Tara Flores',
    occupation: 'Special Education Teacher',
    imageUrl: '',
    type: ['carousel'],
    text: 'Finally, a system where paras can take data. I am tired of losing data sheets and carrying binders with student data; this is exactly what I was aiming at for the new school year.',
  },
  {
    name: 'Sarah Wong',
    occupation: 'Speech Language Pathologist',
    imageUrl: '',
    text: 'I found the team responsive to my suggestions. A few of the ideas I presented have already been implemented, and I am told some others should be live soon. I am impressed with the pace of execution.',
  },
  {
    name: 'Jill Walker',
    occupation: 'Special Education Teacher',
    imageUrl: '',
    text: 'Math has never been my strong suit, and I have always worried if I am collecting data the right way. AbleSpace has allowed me to focus on my teaching by simplifying progress monitoring for me.',
  },
];

export const CarouselReviews = [
  {
    image: testImage,
    ratings: 5,
    text: '15 years and 6 data systems later, finally I have found exactly what I was looking for. It is so easy yet so powerful. And love the selection of available graphs.',
    author: 'John Doe',
  },
  {
    image: testImage,
    ratings: 5,
    text: '15 years and 6 data systems later, finally I have found exactly what I was looking for. It is so easy yet so powerful. And love the selection of available graphs.',
    author: 'John Doe',
  },
  {
    image: testImage,
    ratings: 5,
    text: '15 years and 6 data systems later, finally I have found exactly what I was looking for. It is so easy yet so powerful. And love the selection of available graphs.',
    author: 'John Doe',
  },
];
